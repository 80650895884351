import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Helmet } from "react-helmet";
import Scrollspy from "react-scrollspy";
import uwContext from "../../context/uw/uwContext";
// import "react-tabs/style/react-tabs.css";
import "./ResultPage.css";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Feature from "ol/Feature";
import { transform } from "ol/proj";
import Point from "ol/geom/Point";
// import Map from "../../components/Map";
// import { Layers, WMSTileLayer, VectorLayer } from "../../components/Layers";
// import { vector, tilewms, xyz } from "../../components/Source";
import OSM from "ol/source/OSM";
import View from "ol/View";
import mapObj from "ol/Map";
// import {
//   Controls,
//   FullScreenControl,
//   ZoomControl,
// } from "../../components/Controls";
import { Image as ImageLayer, Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS";

// import FloodRiskSurfaceWater from "./pageSections/FloodRiskSurfaceWater";
import FloodRiskSolutions from "./pageSections/FloodRiskSolutions";
import imgAreasBenefitingFromFlood from "../../images/Areas-Benefiting-from-flood.png";
import imgFloodRiskReservoirs from "../../images/Flood-Risk-Reservoirs.png";
import imgFloodRiskRiversSea from "../../images/Flood-Risk-Rivers-Sea.png";
import imgFloodRiskSurfaceWater from "../../images/Flood-Risk-Surface-water.png";
import imgFloodWarningAreas from "../../images/Flood-Warning-Areas.png";
import imgHistoricFloodMap from "../../images/Historic-Flood-Map.png";
import imgSummaryFloodRiskMap from "../../images/Summary-Flood-Risk-Map.png";
import imgFloodRiskSolutions from "../../images/Flood-Risk-Solutions.png";
import imgHistoricFloodOurline from "../../images/HistoricFloodOurline.jpg";
import Chart from "./components/Chart";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import axios from "axios";
const ResultPage = () => {
  proj4.defs(
    "EPSG:27700",
    "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs"
  );

  register(proj4);
  const pathLocation = useLocation();
  const {
    selectedPlace = {},
    center,
    setCenter,
    setPlace,
    city,
    getCity,
  } = useContext(uwContext);
  // Flood Rish Rivers and Sea
  const [mapfloodRiskRiversAndSea, setMapfloodRiskRiversAndSea] = useState();
  const mapElement_mapfloodRiskRiversAndSea = useRef(); //div reference
  const mapRef_mapfloodRiskRiversAndSea = useRef(); // map referencer
  mapRef_mapfloodRiskRiversAndSea.current = mapfloodRiskRiversAndSea;
  var floodRiskRiversAndSeadict = {
    Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_2:
      "https://environment.data.gov.uk/spatialdata/flood-map-for-planning-rivers-and-sea-flood-zone-2/wms",
    Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_1:
      "https://environment.data.gov.uk/spatialdata/flood-map-for-planning-rivers-and-sea-flood-zone-1/wms",
    Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_3:
      "https://environment.data.gov.uk/spatialdata/flood-map-for-planning-rivers-and-sea-flood-zone-3/wms",
  }; //layer dict
  // Extent of flooding
  const [mapExtentOfFlooding, setMapExtentOfFlooding] = useState();
  const mapElement_mapExtentOfFlooding = useRef(); //div reference
  const mapRef_mapExtentOfFlooding = useRef(); // map referencer
  mapRef_mapExtentOfFlooding.current = mapExtentOfFlooding;
  var ExtentOfFlooding_dict = {
    Risk_of_Flooding_from_Surface_Water_Extent_0_1_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-0-1-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Extent_1_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-1-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Extent_3_3_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-3-3-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Depth_0_1_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-depth-0-1-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Depth_1_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-depth-1-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Depth_3_3_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-depth-3-3-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Hazard_0_1_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-hazard-0-1-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Hazard_1_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-hazard-1-percent-annual-chance/wms",
    Risk_of_Flooding_from_Surface_Water_Hazard_3_3_Percent_Annual_Chance:
      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-hazard-3-3-percent-annual-chance/wms",
  };

  //Flood Risk Reservoirs
  const [FloodRiskReservoirs, setFloodRiskReservoirs] = useState();
  const mapElement_FloodRiskReservoirs = useRef(); //div reference
  const mapRef_FloodRiskReservoirs = useRef(); // map referencer
  mapRef_FloodRiskReservoirs.current = FloodRiskReservoirs;

  // Historic Flood Map
  const [HistoricFloodMap, setHistoricFloodMap] = useState();
  const mapElement_HistoricFloodMap = useRef(); //div reference
  const mapRef_HistoricFloodMap = useRef(); // map referencer
  mapRef_HistoricFloodMap.current = HistoricFloodMap;

  let floodMap;
  let FloodRiskRiversAndSea;
  let ExtentOfFlooding;
  let map_FloodRiskReservoir;
  let map_HistoricFloodMaps;
  let FloodWarningAreas;
  let AreasBenefitingFromFloodDefences;

  let urlLastValue;
  let sanitised;

  useEffect(() => {
    const getFeatures = async (value) => {
      const result = await axios(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&types=address,region,poi,country,district,locality,neighborhood,postcode&country=gb`
      );
      const resultdata = result.data.features;
      if (resultdata.length > 0) {
        const selectedFeature = resultdata.filter(function (feature) {
          if (feature.text.toLowerCase() == sanitised) {
            feature.sanitised = urlLastValue;
            getCity(feature.sanitised);
            setCenter(feature.center);
            return feature;
          }
        });
        const placeresult = {
          selectedFeature: selectedFeature,
        };
        setPlace(placeresult);
      }
    };

    if (typeof selectedPlace === "object" && selectedPlace !== null) {
      if (Object.keys(selectedPlace).length == 0) {
        urlLastValue = pathLocation.pathname.substring(30);
        if (urlLastValue) {
          sanitised = urlLastValue.replace(/-/g, " ").trim().toLowerCase();
          getFeatures(sanitised);
        }
      }
    }
  }, []);

  // -----------------------------
  const [active, setActive] = useState("All");

  const ActiveLabel = (val, layername) => {
    setActive(val);
    mapRef_mapExtentOfFlooding.current.getLayers().forEach((layer) => {
      if (layer.get("name") == "extentFloodingLayer") {
        mapRef_mapExtentOfFlooding.current.removeLayer(layer);
      }
    });
    var flLayer = new TileLayer({
      source: new TileWMS({
        url: ExtentOfFlooding_dict[layername],
        params: {
          LAYERS: layername,
          TILED: true,
        },
        transition: 0,
      }),
      name: "extentFloodingLayer",
    });
    mapRef_mapExtentOfFlooding.current.addLayer(flLayer);
  };

  function toggleFloodZone(event) {
    if (event.checked) {
      var flLayer = new TileLayer({
        source: new TileWMS({
          url: floodRiskRiversAndSeadict[event.value],
          params: {
            LAYERS: event.value,
            TILED: true,
          },
          transition: 0,
        }),
        name: "flood_layer_" + event.value,
      });
      mapRef_mapfloodRiskRiversAndSea.current.addLayer(flLayer);
    } else {
      var allLayers = mapRef_mapfloodRiskRiversAndSea.current.getLayers()
        .array_;
      for (let i = 0; i < allLayers.length; i++) {
        if (allLayers[i].get("name") == "flood_layer_" + event.value) {
          mapRef_mapfloodRiskRiversAndSea.current.removeLayer(allLayers[i]);
        }
      }
    }
  }

  useEffect(() => {
    var layers_floodMap = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
    ];
    floodMap = new mapObj({
      layers: layers_floodMap,
      target: "floodMap",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });
    return () => floodMap.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers_FloodRiskRiversAndSea = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
    ];
    FloodRiskRiversAndSea = new mapObj({
      layers: layers_FloodRiskRiversAndSea,
      target: mapElement_mapfloodRiskRiversAndSea.current,
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });
    setMapfloodRiskRiversAndSea(FloodRiskRiversAndSea);

    return () => FloodRiskRiversAndSea.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers_ExtentOfFlooding = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
    ];
    ExtentOfFlooding = new mapObj({
      layers: layers_ExtentOfFlooding,
      target: mapElement_mapExtentOfFlooding.current,
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });
    setMapExtentOfFlooding(ExtentOfFlooding);

    return () => ExtentOfFlooding.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers_FloodRiskReservoirs = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-reservoirs-maximum-flood-extent/wms",
          params: {
            LAYERS: "Risk_of_Flooding_from_Reservoirs_Maximum_Flood_Extent",
            TILED: true,
          },
          transition: 0,
        }),
      }),
    ];
    map_FloodRiskReservoir = new mapObj({
      layers: layers_FloodRiskReservoirs,
      target: "map_FloodRiskReservoir",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });

    setFloodRiskReservoirs(map_FloodRiskReservoir);

    return () => map_FloodRiskReservoir.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers_HistoricFloodMap = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/historic-flood-map/wms",
          params: {
            LAYERS: "Historic_Flood_Map",
            TILED: true,
          },
          transition: 0,
        }),
      }),
    ];
    map_HistoricFloodMaps = new mapObj({
      layers: layers_HistoricFloodMap,
      target: "HistoricFloodMap",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });

    setHistoricFloodMap(map_HistoricFloodMaps);

    return () => map_HistoricFloodMaps.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers_AreasBenefitingFromFloodDefences = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
    ];
    AreasBenefitingFromFloodDefences = new mapObj({
      layers: layers_AreasBenefitingFromFloodDefences,
      target: "AreasBenefitingFromFloodDefences",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });
    return () => AreasBenefitingFromFloodDefences.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers_FloodWarningAreas = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
    ];
    FloodWarningAreas = new mapObj({
      layers: layers_FloodWarningAreas,
      target: "FloodWarningAreas",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });
    return () => FloodWarningAreas.setTarget(undefined);
  }, [center]);

  const selectedTab_extentFlooding = (index) => {
    mapRef_mapExtentOfFlooding.current.getLayers().forEach((layer) => {
      if (layer.get("name") == "extentFloodingLayer") {
        mapRef_mapExtentOfFlooding.current.removeLayer(layer);
      }
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`${
          selectedPlace.text ? selectedPlace.text : "Flood Risk Map"
        } | Urban Water Map`}</title>
        <meta name="description" content="testing react helmet" />
        <meta name="keywords" content="react,seo,helmet" />
      </Helmet>
      <div className="lg:container mx-auto">
        <div className="grid xl:grid-cols-10 px-4 md:px-8 bg-white spaceTop">
          <div className="col-span-2 mr-2 relative hidden xl:block">
            <div className="absolute w-full">
              <div className="fixed">
                {Object.keys(selectedPlace).length > 0 && (
                  <div className="font-bold text-gray-600 text-xl mb-4 border-b-2 border-gray-200 p-2" style={{maxWidth: "280px"}}>
                    {selectedPlace.text}
                  </div>
                )}

                <Scrollspy
                  className="scrollNav"
                  offset={-115}
                  items={[
                    "section-1",
                    "section-2",
                    "section-3",
                    "section-4",
                    "section-5",
                    "section-6",
                    "section-7",
                  ]}
                  currentClassName="is-current"
                >
                  {Object.keys(selectedPlace).length > 0 && (
                    <li>
                      <a href="#section-1">
                        <img src={imgSummaryFloodRiskMap} /> Summary
                      </a>
                    </li>
                  )}
                  <li>
                    <a href="#section-2">
                      <img src={imgFloodRiskRiversSea} /> Flood Risk Rivers and
                      Sea
                    </a>
                  </li>
                  <li>
                    <a href="#section-3">
                      <img src={imgFloodRiskSurfaceWater} /> Flood Risk Surface
                      Water
                    </a>
                  </li>
                  <li>
                    <a href="#section-4">
                      <img src={imgFloodRiskReservoirs} /> Flood Risk Reservoirs
                    </a>
                  </li>
                  <li>
                    <a href="#section-5">
                      <img src={imgHistoricFloodMap} /> Historic Flood Map
                    </a>
                  </li>
                  <li>
                    <a href="#section-6">
                      <img src={imgAreasBenefitingFromFlood} /> Area Benefiting
                      From Flood Defences
                    </a>
                  </li>
                  <li>
                    <a href="#section-7">
                      <img src={imgFloodWarningAreas} /> Flood Warning Areas
                    </a>
                  </li>
                  <li>
                    <a href="#section-8">
                      <img src={imgFloodRiskSolutions} /> Flood Risk Solutions
                    </a>
                  </li>
                </Scrollspy>
              </div>
            </div>
          </div>
          <div className="col-span-8 resultSec xl:pl-5">
            <section
              id="section-1"
              className="border-b border-gray-200 py-6 my-6"
            >
              <h2 className="text-3xl font-black mb-4">
                Flood Map {selectedPlace.text}
              </h2>
              <div id="floodMap" className="ol-map"></div>
              {Object.keys(city).length > 0 && (
                <div className="py-6 my-6 border border-l-0 border-r-0 border-gray-200">
                  <div className="font-bold text-gray-400 text-2xl mb-4">
                    {selectedPlace.text}
                  </div>
                  <span className="p-2 pl-0 border-r-2 border-gray-200">
                    Flood Risk
                  </span>
                  <span className="p-2">
                    {city.properties} properties at risk of flooding
                  </span>
                </div>
              )}

              {Object.keys(city).length > 0 && (
                <div className="py-6">
                  <h2 className="text-3xl font-black">
                    Flood risk in {selectedPlace.text}
                  </h2>
                  <p className="py-4 md:w-2/4">
                    {city.propertiesPercentage}% of the properties in{" "}
                    {selectedPlace.text} at risk of flooding. The vast majority
                    (4.3%) of the properties are at hight to medium flood. The
                    Environment Agency flood map of {selectedPlace.text} are
                    shown below.
                  </p>
                  <strong>
                    Property type at risk of flooding in {selectedPlace.text}
                  </strong>
                  {Object.keys(city).includes("riskOfFlooding") && (
                    <div className=" border my-4 bg-gray-50 w-3/4 sm:w-2/4 p-4 mx-auto">
                      <Chart chartData={city.riskOfFlooding} />
                    </div>
                  )}

                  <p className="py-4 md:w-2/4">
                    The property type affected by risk in {selectedPlace.text}{" "}
                    are mostly residential.
                  </p>
                  <strong>
                    Property type at risk of flooding in {selectedPlace.text}
                  </strong>
                  {Object.keys(city).includes("riskAffectingGraph") && (
                    <div className="border my-4 bg-gray-50 w-3/4 sm:w-2/4 p-4 mx-auto">
                      <Chart chartData={city.riskAffectingGraph} />
                    </div>
                  )}
                </div>
              )}
            </section>

            <section id="section-2" className="min-h-screen my-6 py-6">
              <div className="pb-6 w-3/4 lg:w-2/4 mx-auto">
                <h2 className="text-3xl font-black">
                  Flood Risk Rivers and Sea - Planning
                </h2>
                <p className="py-6">
                  This map covers the flood risk from rivers and sea for
                  planning purposes. This information was produced by the
                  Environment Agency to support the flood risk assessments that
                  are part of planning applications. The flood risk varies from
                  Flood Zone 1 (low risk) to Flood Zone 3 (high risk).
                  <br />
                  <br />
                  Contact us if you would like to get obtain a detailed flood
                  risk assessment.
                </p>
                <div>
                  <input
                    type="checkbox"
                    value="Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_1"
                    onChange={(event) => toggleFloodZone(event.target)}
                    defaultChecked= {true}
                  />{" "}
                  Flood Zone 1 - Low Risk
                </div>
                <div>
                  <input
                    type="checkbox"
                    value="Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_2"
                    onChange={(event) => toggleFloodZone(event.target)}
                  />{" "}
                  Flood Zone 2 - Medium Risk
                </div>
                <div>
                  <input
                    type="checkbox"
                    value="Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_3"
                    onChange={(event) => toggleFloodZone(event.target)}
                  />{" "}
                  Flood Zone 3 - High Risk
                </div>
              </div>
              <div
                ref={mapElement_mapfloodRiskRiversAndSea}
                className="ol-map"
              ></div>
              {/* <WMSTileLayer source={osm()} zIndex={0} layerName="Basemap" /> */}
              {/* <Map projection="EPSG:27700" center={center} zoom={zoom}>
                <Layers>
                  <WMSTileLayer
                    source={xyz(
                      "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
                      "Made by Rotten Grapes",
                      20
                    )}
                    zIndex={0}
                    layerName="Basemap"
                  />
                  {showFloodZone2 && (
                    <WMSTileLayer
                      source={tilewms(
                        "https://environment.data.gov.uk/spatialdata/flood-map-for-planning-rivers-and-sea-flood-zone-2/wms",
                        "Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_2"
                      )}
                      zIndex={2}
                      layerName="Flood Zone 2"
                    />
                  )}
                  {showFloodZone3 && (
                    <WMSTileLayer
                      source={tilewms(
                        "https://environment.data.gov.uk/spatialdata/flood-map-for-planning-rivers-and-sea-flood-zone-3/wms",
                        "Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_3"
                      )}
                      zIndex={3}
                      layerName="Flood Zone 3"
                    />
                  )}
                </Layers>
                <Controls>
                  <FullScreenControl />
                  <ZoomControl />
                </Controls>
              </Map> */}
            </section>

            <section
              id="section-3"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              {/* <FloodRiskSurfaceWater /> */}
              <div className="p-6 w-3/4 lg:w-2/4 mx-auto">
                <h2 className="text-3xl font-black">
                  Flood Risk Surface Water
                </h2>
                <p className="py-6">
                  The flood risk from surface water is assessed in its extent
                  depth and hazard.
                  <br />
                  <br />
                  Contact us if you would like obtain a detailed flood risk
                  assessment for your site.
                </p>
              </div>
              <Tabs onSelect={(index) => selectedTab_extentFlooding(index)}>
                <TabList>
                  <Tab>Extent</Tab>
                  <Tab>Depth</Tab>
                  <Tab>Hazard</Tab>
                </TabList>

                <TabPanel>
                  <div className="grid lg:grid-cols-5">
                    <div className="md:col-span-2 lg:border-r border-gray-300 px-4 py-8 xl:p-8 text-center text-xs">
                      <div className="mb-2">More likely to happen &rarr;</div>
                      <div className="grid grid-cols-5">
                        <div className="col-span-1">
                          <div className="p-2">Flooding likelihood</div>
                          <div className="p-2">Lavel of risk</div>
                        </div>
                        <div className="col-span-4 grid grid-cols-4 bg-gray-100">
                          <div
                            onClick={(event) => ActiveLabel("extentVL")}
                            className={
                              active == "extentVL" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">&lt; 0.1%</div>
                            <div className="p-2">Very Low</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "extentL",
                                "Risk_of_Flooding_from_Surface_Water_Extent_0_1_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "extentL" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">0.1%</div>
                            <div className="p-2">Low</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "extentM",
                                "Risk_of_Flooding_from_Surface_Water_Extent_1_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "extentM" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">1%</div>
                            <div className="p-2">medium</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "extentH",
                                "Risk_of_Flooding_from_Surface_Water_Extent_3_3_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "extentH" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">3.3%</div>
                            <div className="p-2">High</div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        &larr; More properties impacted
                      </div>
                    </div>
                    <div className="p-8 md:col-span-3">
                      <h3 className="text-xl font-black">Extent of Flooding</h3>
                      <p className="py-6">
                        The extent of flooding refers to the area that could be
                        at risk of flooding from surface water. This information
                        is not to be used at property level.
                        <br />
                        <br />
                        Please contact us if you need a detailed flood risk
                        assessment.
                      </p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  {/* <Depth /> */}
                  <div className="grid lg:grid-cols-5">
                    <div className="md:col-span-2 lg:border-r border-gray-300 px-4 py-8 xl:p-8 text-center text-xs">
                      <div className="mb-2">More likely to happen &rarr;</div>
                      <div className="grid grid-cols-5">
                        <div className="col-span-1">
                          <div className="p-2">Flooding likelihood</div>
                          <div className="p-2">Lavel of risk</div>
                        </div>
                        <div className="col-span-4 grid grid-cols-4 bg-gray-100">
                          <div
                            onClick={(event) => ActiveLabel("depthVL")}
                            className={
                              active == "depthVL" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">&lt; 0.1%</div>
                            <div className="p-2">Very Low</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "depthL",
                                "Risk_of_Flooding_from_Surface_Water_Depth_0_1_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "depthL" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">Below 300</div>
                            <div className="p-2">Low</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "depthM",
                                "Risk_of_Flooding_from_Surface_Water_Depth_1_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "depthM" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">900mm to 300mm</div>
                            <div className="p-2">medium</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "depthH",
                                "Risk_of_Flooding_from_Surface_Water_Depth_3_3_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "depthH" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">&gt; 900mm</div>
                            <div className="p-2">High</div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        &larr; More properties impacted
                      </div>
                    </div>
                    <div className="p-8 md:col-span-3">
                      <h3 className="text-xl font-black">Depth of water</h3>
                      <p className="py-6">
                        The deeper the water the more risk.
                      </p>
                      <ul className="grid sm:grid-cols-3">
                        <li className="flex item-center">
                          <span className="w-5 h-5 inline-block rounded-full bg-blue-200 mr-3"></span>{" "}
                          Over 1 feet
                        </li>
                        <li className="flex item-center">
                          <span className="w-5 h-5 inline-block rounded-full bg-blue-400 mr-3"></span>{" "}
                          1 feet to 3 foot
                        </li>
                        <li className="flex item-center">
                          <span className="w-5 h-5 inline-block rounded-full bg-blue-600 mr-3"></span>{" "}
                          Over 3 feet
                        </li>
                      </ul>
                      <div className="mt-2 text-center text-xs">
                        Deeper Water &rarr;
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  {/* <Hazard /> */}
                  <div className="grid lg:grid-cols-5">
                    <div className="md:col-span-2 lg:border-r border-gray-300 px-4 py-8 xl:p-8 text-center text-xs">
                      <div className="mb-2">More likely to happen &rarr;</div>
                      <div className="grid grid-cols-5">
                        <div className="col-span-1">
                          <div className="p-2">Flooding likelihood</div>
                          <div className="p-2">Lavel of risk</div>
                        </div>
                        <div className="col-span-4 grid grid-cols-4 bg-gray-100">
                          <div
                            onClick={(event) => ActiveLabel("hazardVL")}
                            className={
                              active == "hazardVL" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">&lt; 0.1%</div>
                            <div className="p-2">Very Low</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "hazardL",
                                "Risk_of_Flooding_from_Surface_Water_Hazard_0_1_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "hazardL" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">0.75 to 1.25</div>
                            <div className="p-2">Low</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "hazardM",
                                "Risk_of_Flooding_from_Surface_Water_Hazard_1_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "hazardM" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">1.25 to 2.0</div>
                            <div className="p-2">medium</div>
                          </div>
                          <div
                            onClick={(event) =>
                              ActiveLabel(
                                "hazardH",
                                "Risk_of_Flooding_from_Surface_Water_Hazard_3_3_Percent_Annual_Chance"
                              )
                            }
                            className={
                              active == "hazardH" ? "selectedLavel" : ""
                            }
                          >
                            <div className="p-2">&gt; 2.0</div>
                            <div className="p-2">High</div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        &larr; More properties impacted
                      </div>
                    </div>
                    <div className="p-8 md:col-span-3">
                      <h3 className="text-xl font-black">Hazard</h3>
                      <p className="py-6">
                        The higher the number the more danger
                      </p>
                      <ul className="grid sm:grid-cols-3 gap-4">
                        <li>
                          <div className="flex item-center mb-3">
                            <span className="w-5 h-5 inline-block rounded-full bg-yellow-300 mr-3"></span>{" "}
                            0.75 - 1.25
                          </div>
                          <p className="text-xs">
                            Moderate Hazard- Danger for some includes children,
                            the elderly and the infirm
                          </p>
                        </li>
                        <li>
                          <div className="flex item-center mb-3">
                            <span className="w-5 h-5 inline-block rounded-full bg-yellow-900 mr-3"></span>{" "}
                            1.25 - 2.0
                          </div>
                          <p className="text-xs">
                            Significant Hazard- Danger for most includes the
                            general public
                          </p>
                        </li>
                        <li>
                          <div className="flex item-center mb-3">
                            <span className="w-5 h-5 inline-block rounded-full bg-red-600 mr-3"></span>{" "}
                            More than 2.0
                          </div>
                          <p className="text-xs">
                            Extreme Hazard- Danger for all includes the
                            emergency services
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
              <div
                ref={mapElement_mapExtentOfFlooding}
                className="ol-map"
              ></div>
            </section>

            <section
              id="section-4"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="py-6 w-3/4 lg:w-2/4 mx-auto">
                <h2 className="text-3xl font-black">Flood Risk Reservoirs</h2>
                <p className="py-6">
                  This shows the maximum extent if a reservoir were fail and
                  release its water. Ths is the worst case scenario, it's
                  unlikely that any actual flood would be this big. These data
                  are intended for emergency planning only.
                  <br />
                  <br />
                  Contact us if you would like obtain a detailed flood risk
                  assessment for your site.
                </p>
                <div className="flex item-center">
                  <span className="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3"></span>{" "}
                  Max Flood Extent
                </div>
              </div>
              <div id="map_FloodRiskReservoir" className="ol-map"></div>
            </section>

            <section
              id="section-5"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="py-6 w-3/4 lg:w-2/4 mx-auto">
                <h2 className="text-3xl font-black">Historic Flood Map</h2>
                <p className="py-6">
                  This shows areas of land that have been previously flooded in
                  England. It is possible that an area has been flooded but has
                  not been recorded. It is also possible that new area flood or
                  not flood at the moment. This data does not indicate if a
                  property has been flooded internally.
                  <br />
                  <br />
                  Contact us if you would like obtain a detailed flood risk
                  assessment for your site.
                </p>
                <div className="flex item-center">
                  <span
                    className="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3 bg-cover border"
                    style={{
                      backgroundImage: `url(${imgHistoricFloodOurline})`,
                    }}
                  ></span>{" "}
                  Historic Flood Ourline
                </div>
              </div>
              <div id="HistoricFloodMap" className="ol-map"></div>
            </section>

            <section
              id="section-6"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="py-6 w-3/4 lg:w-2/4 mx-auto">
                <h2 className="text-3xl font-black">
                  Areas Benefiting from flood defences
                </h2>
                <p className="py-6">
                  This map shows areas benefiting from flood defences in a 1 in
                  100 (1%) chance of flooding each year from rivers; or 1 in 200
                  (0.5 %) chance of flooding each year from the sea.
                </p>
              </div>
              <div
                id="AreasBenefitingFromFloodDefences"
                className="ol-map"
              ></div>
            </section>

            <section
              id="section-7"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="py-6 w-3/4 lg:w-2/4 mx-auto">
                <h2 className="text-3xl font-black">Flood warning areas</h2>
                <p className="py-6">
                  This map shows the areas where the Flood Warning Service is
                  provided by the Environment Agency. We recomend you{" "}
                  <a
                    href="https://www.gov.uk/sign-up-for-flood-warnings"
                    target="_blank"
                  >
                    register
                  </a>{" "}
                  to the service if your property is expected to flood from
                  fiver or sea.
                </p>
                <div className="flex item-center">
                  <span
                    className="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3 bg-cover border"
                    style={{
                      backgroundImage: `url(${imgHistoricFloodOurline})`,
                    }}
                  ></span>{" "}
                  Flood Warning areas
                </div>
              </div>
              <div id="FloodWarningAreas" className="ol-map"></div>
            </section>

            <section
              id="section-8"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <FloodRiskSolutions />
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResultPage;

import React, { useEffect } from "react";
import "./TestMap.css"
import "ol/ol.css";
import ImageWMS from "ol/source/ImageWMS";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import View from "ol/View";
import { Image as ImageLayer, Tile as TileLayer } from "ol/layer";

const TestMap = () => {
    var map

    function toggle_layer(e) {

        if (e.target.checked) {
            let new_img_layer = new ImageLayer({
              extent: [-13884991, 2870341, -7455066, 6338219],
              source: new ImageWMS({
                url: "https://ahocevar.com/geoserver/wms",
                params: { LAYERS: e.target.value },
                ratio: 1,
                serverType: "geoserver",
              }),
              name: e.target.value,
            });
            map.addLayer(new_img_layer);
          
        } else {
              let allLayerlist = map.getLayers().array_;
              for (let i = 0; i < allLayerlist.length; i++) {
                if (allLayerlist[i].get("name") == e.target.value) {
                  map.removeLayer(allLayerlist[i]);
                  break;
                }
              }
        }
    }
    useEffect(() => {
    var layers = [
      new TileLayer({
        source: new OSM(),
      })
    ];
    map = new Map({
      layers: layers,
      target: "MapOne",
      view: new View({
        center: [-10997148, 4569099],
        zoom: 4,
      }),
    });
    }, []);


   
    return (
      <div className="mt-36">
        <input type="checkbox" onChange={toggle_layer}  value="topp:states" /> USA LAYER
        <br></br>
        <input type="checkbox" /> Two<br></br>
        <input type="checkbox" /> Three<br></br>
        <div id="MapOne" className="map h-96 bg-gray-300 mb-5"></div>
        <div id="MapTwo" className="map h-96 bg-gray-300 mb-5"></div>
        <div id="MapThree" className="map h-96 bg-gray-300 mb-5"></div>
      </div>
    );
}

export default TestMap

import React, { Fragment } from "react";
import Searchbar from "../../components/Searchbar/Searchbar";
import OurServices_card from "../../components/OurServices_card/OurServices_card";
import ExploreUrban_card from "../../components/ExploreUrban_card/ExploreUrban_card";
import SearchInput from "../../components/Searchbar/SearchInput";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Urban Water Map</title>
        <meta name="description" content="testing react helmet" />
        <meta name="keywords" content="react,seo,helmet" />
      </Helmet>
      <Searchbar />
      <div className="space-y-3 md:w-3/4 mx-auto p-8">
        <p>
          Our maps provide flood risk information and flood protection
          intervention in an easy to use format. Check out our maps below.
        </p>
        <h2 className="text-2xl font-bold">Find your flood risk</h2>
        <p>Take steps to protect your assets and yourself</p>
        <div className="shadow-lg rounded-md py-2 border">
          <SearchInput />
        </div>
      </div>
      <OurServices_card />
      <ExploreUrban_card />
    </Fragment>
  );
};

export default HomePage;

import React, { useState, useContext } from "react";
import Autosuggest from "react-autosuggest";
import { useHistory, useLocation } from "react-router-dom";
import uwContext from "../../context/uw/uwContext";
import "./Searchbar.css";
import axios from "axios";

//  import image from '../components/images/div_1_image.jpg';
const SearchInput = () => {
  const { setPlace, getCity, setCenter } = useContext(uwContext);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [features, setFeatures] = useState([]);
  const history = useHistory();
  const sugs = [];

  const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  const getFeatures = async (value) => {
    const result = await axios(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&types=address,region,poi,country,district,locality,neighborhood,postcode&country=gb`,
      )
      const resultdata = result.data.features;
      setFeatures(resultdata)
      if (resultdata.length > 0) {
        resultdata.map(function (feature) {
          sugs.push(feature.text);
        });
        setSuggestions(sugs);
      }
  };

  const getSuggestions = value => {
    const escapedValue = escapeRegexCharacters(value.trim().toLowerCase());

    if (escapedValue === "") {
      return sugs;
    }

    getFeatures(escapedValue)
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => suggestion;

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, method }
  ) => {
    event.preventDefault();

    let place = suggestion.replace(/\s+/g, "-").toLowerCase();

    const selectedFeature = features.filter(function (feature) {
      if (feature.text == suggestion) {
        feature.sanitised = suggestion.replace(/\s+/g, "-").toLowerCase();
        getCity(feature.sanitised);
        setCenter(feature.center)
        return feature;
      }
    });

    const result = {
      selectedFeature: selectedFeature
    };
    setPlace(result);
    
    history.push("/nature-based-solutions-" + place);
  };

  const renderInputComponent = inputProps => (
    <div className="inputContainer">
      <input {...inputProps} />
    </div>
  );

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    setFeatures([]);
  };

  const inputProps = {
    placeholder:  "Enter address, post code, city",
    value,
    onChange: onChange,
  };
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
    />
  );
};
export default SearchInput;

import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Faqs = () => {
  return (
    <div className="p-8 space-y-3 md:w-3/4 mx-auto">
      <h2 className="text-3xl font-black mb-4">Frequently asked questions</h2>
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        <AccordionItem
          itemscope
          itemprop="mainEntity"
          itemtype="https://schema.org/Question"
        >
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 itemprop="name">
                What is an Environment Agency flood risk map?
              </h3>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            itemscope
            itemprop="acceptedAnswer"
            itemtype="https://schema.org/Answer"
          >
            <div itemprop="text">
              <p>
                The Environment Agency flood risk maps are map created to assess
                the likely of flooding or certain areas in UK.
              </p>
              <p>
                These map includes flood from rivers and sea for planning
                purposes, surface water run-off maps and reservoir maps.
              </p>
              <p>
                For instance the Flood Map for Planning (Rivers and Sea)
                includes several layers that covers Flood Zone 2 and Flood Zone
                3. It evaluates the risk of flooding by ignoring the presence of
                flood defences.
              </p>
              <p>
                The flood risk maps are produced to support the flood risk
                assessments in line with Planning Practice Guidance.
              </p>
              <p>
                The Environment Agency flood risk maps also helps people living
                in flood area to understanding of flooding and make them aware
                of the hazards.
              </p>
              <p>
                The Environment Agency flood risk maps area based on modelled
                data with substantial assumptions being made. This information
                therefore should be taken as indicative and should not be used
                for specific sites or individual properties. This is because,
                the Environment Agency does have the final floor levels of your
                property.
              </p>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          itemscope
          itemprop="mainEntity"
          itemtype="https://schema.org/Question"
        >
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 itemprop="name">What is flood risk mapping?</h3>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            itemscope
            itemprop="acceptedAnswer"
            itemtype="https://schema.org/Answer"
          >
            <div itemprop="text">
              <p>
                Flood Risk Mapping identifies areas that are at significant risk
                of flooding. This identification has been completed by the
                Environment Agency and includes databases such as the main
                rivers and sea and the lead local flood authorities which are in
                charge of the surface water flooding.
              </p>
              <p>
                In general flood risk areas are determined where Flood Hazard
                and Risk Maps and Flood Risk Management Plans must subsequently
                be produced to meet national guidelines.
              </p>
              <p>You can explore the more relevant maps in our website.</p>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem
          itemscope
          itemprop="mainEntity"
          itemtype="https://schema.org/Question"
        >
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 itemprop="name">How to evaluate flood risk maps?</h3>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            itemscope
            itemprop="acceptedAnswer"
            itemtype="https://schema.org/Answer"
          >
            <div itemprop="text">
              <p>
                The evaluation of flood maps to identify the risk of flooding to
                indidual property is not feasible. This is because to be used at
                regional or local level and does not contain the final floor
                level of the property.
              </p>
              <p>
                In order to understand the flood maps, it is necessary to look
                at the Flooding from Surface Water (RoFSW) and the flood risk
                from Rivers and Sea for planning, the risk of flooding from
                other sources, the ground water flooding (not included by the
                Environment Agency) and the risk from sewer or location in
                critical drainage areas. Once you have the information you would
                be able to see the property and compare it with the risk of
                flooding.
              </p>
              <p>
                It is recommended that the assessment iis carried out by a
                competent person who is able to determine the level of your
                property and obtain the flood levels from the Environment
                Agency.
              </p>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Faqs;

import React from 'react'
import '../Footer/Footer.css'
import logo from '../../images/Urban_Water_cv.png';
 function Footer() {
     return (
       <div>
         <div className="Footer text-center">
           <img
             src={logo}
             className="mx-auto fill-current h-14 "
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeMiterlimit="1.5"
             clipRule="evenodd"
             viewBox="0 0 716 895"
           />
           <h1 className="Footer_head">Creating a better planet</h1>
           <h3 className="h3_foot">
             Flood Risk Assessments ready within 48 hrs.
           </h3>

           <div className="grid sm:grid-cols-3 justify-items-stretch mb-4">
             <div className="justify-self-center">
               <i className="fas fa-phone"></i> +1 (344) 532-2352{" "}
             </div>
             <div className="justify-self-center">
               <i className="fas fa-envelope"></i> info@urban-water.co.uk{" "}
             </div>
             <div className="justify-self-center">
               <i className="fas fa-map-marker-alt"></i> List Item #1
             </div>
           </div>

           <button className="bg-white hover:bg-green-100 text-green-400 font-semibold py-2 px-4 border border-green-400 rounded-full shadow">
             Get a free quote →
           </button>
         </div>

         <div className="last flex justify-between items-center p-2 ">
           <p className="foot text-gray-50">
             Urban Water © 2021 | All rights reserved.
           </p>
           {/* <div className='grid grid-cols-4 gap-2 '>
         <i className="fab fa-facebook"></i>
         <i className="fab fa-twitter"></i>
         <i className="fab fa-youtube"></i>
         <i className="fas fa-rss"></i>
         </div> */}
           <div class="text-xs gray-100">
             {" "}
             Made with ❤ by{" "}
             <a  href="https://www.krishnaglodha.com" target="_blank">
               {" "}
               Rotten Grapes Pvt. Ltd.{" "}
             </a>
           </div>
         </div>
       </div>
     );
 }

 export default Footer
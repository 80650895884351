import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef
} from "react";
// import ReactDOM from "react-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Scrollspy from "react-scrollspy";
import uwContext from "../../context/uw/uwContext";
// import "react-tabs/style/react-tabs.css";
import "./NbsResultPage.css";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
// import Feature from "ol/Feature";
import { transform } from "ol/proj";

// import Point from "ol/geom/Point";
// import Map from "../../components/Map";
// import { Layers, WMSTileLayer, VectorLayer } from "../../components/Layers";
// import { osm, vector, tilewms, xyz } from "../../components/Source";
// import {
//   Controls,
//   FullScreenControl,
//   ZoomControl,
// } from "../../components/Controls";
// import RunoffAttenuationAreas from "./pageSections/RunoffAttenuationAreas";
import imgRainGardensAndBioswales from "../../images/RainGardensAndBioswales.png";
import imgSupport from "../../images/Support.png";
import imgUpgradedRoads from "../../images/UpgradedRoads.png";
import imgPonds from "../../images/Ponds.png";
import imgBeachDunesAndRenourrishment from "../../images/BeachDunesAndRenourrishment.png";
// import Chart from "./components/Chart";

import "ol/ol.css";
// import ImageWMS from "ol/source/ImageWMS";
import Map from "ol/Map";
import { OSM, Vector as VectorSource } from "ol/source";
import View from "ol/View";
import TileWMS from "ol/source/TileWMS";
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
} from "ol/layer";
import XYZ from "ol/source/XYZ";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import GeoJSON from "ol/format/GeoJSON";
// import { layer } from "@fortawesome/fontawesome-svg-core";
import Overlay from "ol/Overlay";
import axios from "axios";

const NBSResultPage = () => {
  const pathLocation = useLocation();
  const {
    selectedPlace = {},
    center,
    setCenter,
    setPlace
  } = useContext(uwContext);

  let urlLastValue;
  let sanitised;
  const [mapCaseStudies, setMapCaseStudies] = useState();
  const [clickedPoint, setclickedPoint] = useState({
    title: 'Click on Point to see Information'
  });

  const mapElement_mapCaseStudies = useRef();
  const ref_container = useRef();
  const ref_content = useRef();
  const ref_closer = useRef();
  const [attenuationmap, setattenuationmap] = useState();
  const mapElement_attenuationmap = useRef(); //div reference
  const mapRef_attenuationmap = useRef(); // map referencer
  mapRef_attenuationmap.current = attenuationmap;

  var mapFloodplainReconnection;
  var mapFloodplaindWoodlandPlanting;
  var mapWoodlandWaterInfiltration;
  var mapWoodlandPlantingNearWatercourses;
  var mapRunoffOneInThirty;
  var mapRunoffOneInHundred;

  useEffect(() => {
    const getFeatures = async (value) => {
      const result = await axios(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&types=address,region,poi,country,district,locality,neighborhood,postcode&country=gb`,
      )
      const resultdata = result.data.features;
      if (resultdata.length > 0) {
        const selectedFeature = resultdata.filter(function (feature) {
          if (feature.text.toLowerCase() == sanitised) {
            feature.sanitised = urlLastValue
            setCenter(feature.center)
            return feature
          }
        });
        const placeresult = {
          selectedFeature: selectedFeature
        };
        setPlace(placeresult);
      }
    };

    if (typeof selectedPlace === "object" && selectedPlace !== null) {
      if (Object.keys(selectedPlace).length == 0) {
        urlLastValue = pathLocation.pathname.substring(24);
        if (urlLastValue) {

          sanitised = urlLastValue.replace(/-/g, " ").trim().toLowerCase();
          getFeatures(sanitised)
        }
      }
    }
  },[]);

  useEffect(() => {
    var layers = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/wwnp-floodplain-reconnection-potential/wms",

          params: {
            LAYERS: "WWNP_Floodplain_Reconnection_Potential",
            TILED: true,
          },
          // Countries have transparency, so do not fade tiles:
          transition: 0,
        }),
      }),
    ];
    mapFloodplainReconnection = new Map({
      layers: layers,
      target: "mapFloodplainReconnection",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 12,
      }),
    });

    return () => mapFloodplainReconnection.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/wwnp-wider-catchment-woodland-potential/wms",

          params: {
            LAYERS: "WWNP_Wider_Catchment_Woodland_Potential",
            TILED: true,
          },
          // Countries have transparency, so do not fade tiles:
          transition: 0,
        }),
      }),
    ];

    mapWoodlandWaterInfiltration = new Map({
      layers: layers,
      target: "mapWoodlandWaterInfiltration",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 15,
      }),
    });
    return () => mapWoodlandWaterInfiltration.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/wwnp-floodplain-woodland-potential/wms",

          params: {
            LAYERS: "WWNP_Floodplain_Woodland_Potential",
            TILED: true,
          },
          // Countries have transparency, so do not fade tiles:
          transition: 0,
        }),
      }),
    ];

    mapFloodplaindWoodlandPlanting = new Map({
      layers: layers,
      target: "mapFloodplaindWoodlandPlanting",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 15,
      }),
    });
    return () => mapFloodplaindWoodlandPlanting.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/wwnp-riparian-woodland-potential/wms",

          params: {
            LAYERS: "WWNP_Riparian_Woodland_Potential ",
            TILED: true,
          },
          // Countries have transparency, so do not fade tiles:
          transition: 0,
        }),
      }),
    ];

    mapWoodlandPlantingNearWatercourses = new Map({
      layers: layers,
      target: "mapWoodlandPlantingNearWatercourses",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 15,
      }),
    });
    // mapWoodlandPlantingNearWatercourses.on('moveend', function name(e) {
    //   console.log(e)
    // })
    return () => mapWoodlandPlantingNearWatercourses.setTarget(undefined);
  }, [center]);

  proj4.defs(
    "EPSG:27700",
    "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs"
  );
  register(proj4);

  useEffect(() => {
    var layers = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-0-1-percent-annual-chance/wms",
          params: {
            LAYERS: "WWNP_Runoff_Attenuation_Features_3_3_percent_AEP ",
            TILED: true,
          },
          transition: 0,
        }),
      }),
    ];
    mapRunoffOneInThirty = new Map({
      layers: layers,
      target: "mapRunoffOneInThirty",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 15,
      }),
    });

    return () => mapRunoffOneInThirty.setTarget(undefined);
  }, [center]);

  useEffect(() => {
    var layers = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-0-1-percent-annual-chance/wms",
          params: {
            LAYERS: "WWNP_Runoff_Attenuation_Features_1_percent_AEP ",
            TILED: true,
          },
          transition: 0,
        }),
      }),
    ];

    mapRunoffOneInHundred = new Map({
      layers: layers,
      target: "mapRunoffOneInHundred",
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 15,
      }),
    });

    return () => mapRunoffOneInHundred.setTarget(undefined);
  }, [center]);

  let geoJSONObject = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [429619.0, 103812.99],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "New Forest LIFE III Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [546468, 184858],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Mayes Brook river restoration",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [413237, 155959],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "River Avon Restoration Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [606200, 336100],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "River Glaven, North Norfolk",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [570963, 208053],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Chelmer Valley Local Nature Reserve",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [348883, 358162],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Mill Brook – Tattenhall",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [207257, 54223],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "St Austell Bay Resilient Regeneration Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [323815, 644761],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Eddleston Water Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [362412, 388613],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Padgate Brook River Restoration",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [313650, 527400],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Low Stanger Floodplain Reconnection",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [479700, 484100],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Slowing the Flow at Pickering",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [385405, 204955],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Stroud Rural SuDS",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [385298, 618511],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "The Bowmont Catchment",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [309349, 95461],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Devon Beaver Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [410900, 633800],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Belford Natural Flood Management Scheme",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [353524, 396909],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Blackbrook Slow the Flow",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [408449, 513341],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Tutta Beck",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [550688, 183769],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Beam Washlands",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [292000, 146700],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "From Source to Sea: the Holnicote Experience",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [439461, 516268],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Lustrum Beck Flood Alleviation Scheme",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [462038, 515843],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Guisborough Flood Alleviation Scheme",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [352200, 513700],
        },
        properties: {
          shape: "Point",
          type: "Rivers",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Swindale Valley, Haweswater",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [369400, 577700],
        },
        properties: {
          shape: "Point",
          type: "Woodlands",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Conifer afforestation on catchment hydrology at Coalburn",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [469200, 352400],
        },
        properties: {
          shape: "Point",
          type: "Woodlands",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Brackenhurst Natural Flood Management",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [465206, 402645],
        },
        properties: {
          shape: "Point",
          type: "Woodlands",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Inspiring Water Action in the Torne",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [306100, 307500],
        },
        properties: {
          shape: "Point",
          type: "Woodlands",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Upland Land Use Management on Flood Risk at Pontbren",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [349800, 129100],
        },
        properties: {
          shape: "Point",
          type: "Woodlands",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Floodplain woodland on flood flows in the River Cary",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [331100, 218100],
        },
        properties: {
          shape: "Point",
          type: "Woodlands",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title:
            "Semi-natural floodplain woodland and flood flows at Great Triley Wood",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [543400, 120300],
        },
        properties: {
          shape: "Point",
          type: "Woodlands",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Sussex Flow Initiative",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [322800, 123000],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Hills to Levels Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [338741, 545001],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Roe and Ive",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [474500, 305500],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Water Friendly Farming Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [458500, 217400],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "River Ray Rural Flooding",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [408961, 389435],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Making Space for Water",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [277784, 139304],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Exmoor Mires Partnership",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [279949, 296500],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Pumlumon Peatland Restoration for Floodwater Management",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [338600, 529500],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Eycott Hill",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [279947, 714088],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Dunruchan Farm Peatland Restoration Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [309496, 366240],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Afon Clwyd",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [292156, 368166],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Nant Barrog",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [617864, 263388],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Debenham Flood Storage",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [427200, 218600],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Evenlode Catchment Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [372150, 565921],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Haltwhistle Burn",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [391145, 439159],
        },
        properties: {
          shape: "Point",
          type: "Runoff",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Trawden Natural Flood Management",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [512532, 426693],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Humber Estuary Erosion Protection Programme",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [569799, 344176],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "North Norfolk Coast",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [279000, 874000],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Nigg Bay Coastal Realignment Project",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [342135, 425754],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Hesketh Out Marsh Managed Realignment",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [483000, 95000],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Medmerry Managed Realignment",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [525459, 175281],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Wandsworth Riverside Quarter",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [603455, 219785],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Fingringhoe Managed Realignment",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [594357, 118509],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Rye Harbour Farm Regulated Tidal Exchange",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [487715, 422788],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Alkborough Flats Managed Realignment",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [635188, 160787],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Sandwich Tidal Defence Scheme",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [624754, 238013],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Levington Saltmarsh Restoration",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [324254, 178170],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Rhymney Great Wharf",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [628497, 244855],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Waldringfield Flood Defence Scheme",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [328606, 403932],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Hightown Sand Dune Restoration",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [267700, 41300],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "South Milton Sands",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [506500, 101500],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Pevensey Sea Defences",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [405804, 88389],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Poole Bay Beach Replenishment Trial",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [488908, 96931],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Pagham Harbour Bypassing",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [523548, 104513],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Shoreham Harbour Shingle Bypassing and Recycling",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [404353, 87391],
        },
        properties: {
          shape: "Point",
          type: "Coasts",
          link:
            "https://drive.google.com/file/d/12SBwd6G0bG4DztTJ3xYXOJKwBUX-ec_0/view?usp=sharing",
          title: "Sandscaping",
        },
      },
    ],
    crs: {
      type: "name",
      properties: {
        name: "EPSG:27700",
      },
    },
  };

  var geojson_styles = {
    Rivers: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: "rgb(29, 78, 216)",
        }),
        stroke: new Stroke({ color: "rgb(29, 78, 216)", width: 1 }),
      }),
    }),
    Woodlands: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: "rgb(4, 120, 87)",
        }),
        stroke: new Stroke({ color: "rgb(4, 120, 87)", width: 1 }),
      }),
    }),
    Runoff: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: "rgb(220, 38, 38)",
        }),
        stroke: new Stroke({ color: "rgb(220, 38, 38)", width: 1 }),
      }),
    }),
    Coasts: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: "rgb(124, 58, 237)",
        }),
        stroke: new Stroke({ color: "rgb(124, 58, 237)", width: 1 }),
      }),
    }),
  };
  var styleFunction = function (feature) {
    return geojson_styles[feature.getProperties().type];
  };
  var vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geoJSONObject),
  });
  var vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
    name: "vectLayer",
  });
  useEffect(() => {
    // var overlay = new Overlay({
    //   element: ref_container.current,
    //   autoPan: true,
    //   autoPanAnimation: {
    //     duration: 250,
    //   },
    // });
    // console.log(ref_closer.current);
    // var closer = ref_closer.current;
    // closer.onclick = function () {
    //   overlay.setPosition(undefined);
    //   closer.blur();
    //   return false;
    // };
    var layers_casestudies = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      vectorLayer,
    ];

    const map_var = new Map({
      layers: layers_casestudies,
      target: mapElement_mapCaseStudies.current,
      view: new View({
        center: [383966.7975410375, 269624.1298685467],
        projection: "EPSG:27700",
        zoom: 7,
      }),
    });
    map_var.on("click", function (evt) {
      var foundFeat = false
      var clicked_feature = map_var.forEachFeatureAtPixel(
        evt.pixel,
        function (feature, layer) {
          if (feature) {
            foundFeat = true
            var content = ref_content.current;
            console.log(layer.get("name") + " was clicked.");
            setclickedPoint({
              title: feature.getProperties().title,
              link: feature.getProperties().link,
              text: "Click to see report",
            });
            // content.innerHTML =
            //   "<code>" +
            //   feature.getProperties().title +
            //   "</code> <a href='" +
            //   feature.getProperties().link +
            //   "' > + Click to See Report </a>";
            // overlay.setPosition([530353.2279460508, 180334.5727748781]); //(feature.getGeometry().getCoordinates());
          } else {
           
            // overlay.setPosition(undefined);
          }
        }
      );
      if (!foundFeat) {
        setclickedPoint({
          title: "Click on Point to see Information",
          link: "",
          text: ""
        });
      }
    });

    setMapCaseStudies(map_var);
    return () => map_var.setTarget(undefined);
  },[])

useEffect(() => {
    var layers_attenuationmap = [
      new TileLayer({
        source: new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
          attributions: "Made by Rotten Grapes",
        }),
      }),
      new TileLayer({
        source: new TileWMS({
          url:
            "https://environment.data.gov.uk/spatialdata/wwnp-runoff-attenuation-features-3-3-percent-aep/wms",
          params: {
            LAYERS: "WWNP_Runoff_Attenuation_Features_3_3_percent_AEP",
            TILED: true,
          },
          transition: 0,
        }),
        name: "attenuation",
      }),
    ];

    let map_attenuationmap = new Map({
      layers: layers_attenuationmap,
      target: mapElement_attenuationmap.current,
      view: new View({
        center: transform(center, "EPSG:4326", "EPSG:27700"),
        projection: "EPSG:27700",
        zoom: 15,
      }),
    });
    setattenuationmap(map_attenuationmap);
    return () => map_attenuationmap.setTarget(undefined);
  }, [center]);

  const [active, setActive] = useState("All");

  const ActiveLabel = (val) => {
    var dummy_vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(geoJSONObject),
    });
    let filteredFeat = [];
    if (val !== "All") {
      let vecObjt = dummy_vectorSource.getFeatures();
      for (let feat = 0; feat < vecObjt.length; feat++) {
        if (vecObjt[feat].getProperties().type === val) {
          filteredFeat.push(vecObjt[feat]);
        }
      }
      let newSource = new VectorSource({
        features: filteredFeat,
      });
      var new_vectorLayer = new VectorLayer({
        source: newSource,
        style: styleFunction,
        name: "vectLayer",
      });
    } else {
      var new_vectorLayer = new VectorLayer({
        source: dummy_vectorSource,
        style: styleFunction,
        name: "vectLayer",
      });
    }

    setActive(val);
    var currentLayers = mapCaseStudies.getLayers().array_;
    for (let i = 0; i < currentLayers.length; i++) {
      if (currentLayers[i].get("name") == "vectLayer") {
        mapCaseStudies.removeLayer(currentLayers[i]);
        mapCaseStudies.addLayer(new_vectorLayer);
        mapCaseStudies.updateSize();
        break;
      }
    }
    // vectorLayer.getSource().refresh();
  };

  const selectedTab_attenuation = (index) => {
    mapRef_attenuationmap.current.getLayers().forEach((layer) => {
      if (layer.get("name") == "attenuation") {
        mapRef_attenuationmap.current.removeLayer(layer);
      }
    });

    switch (index) {
      case 0:
        let new_attenuation = new TileLayer({
          source: new TileWMS({
            url:
              "https://environment.data.gov.uk/spatialdata/wwnp-runoff-attenuation-features-3-3-percent-aep/wms",
            params: {
              LAYERS: "WWNP_Runoff_Attenuation_Features_3_3_percent_AEP",
              TILED: true,
            },
            transition: 0,
          }),
          name: "attenuation",
        });
        mapRef_attenuationmap.current.addLayer(new_attenuation);
        break;
      case 1:
        console.log("Attenuation Areas 1:100 Storm Events tab selected");
        let new_attenuation_1 = new TileLayer({
          source: new TileWMS({
            url:
              "https://environment.data.gov.uk/spatialdata/wwnp-runoff-attenuation-features-1-percent-aep/wms",
            params: {
              LAYERS: "WWNP_Runoff_Attenuation_Features_1_percent_AEP",
              TILED: true,
            },
            transition: 0,
          }),
          name: "attenuation",
        });
        mapRef_attenuationmap.current.addLayer(new_attenuation_1);
        break;
      default:
        return;
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`${
          selectedPlace.text ? selectedPlace.text : "Nature Based Solutions"
        } | Urban Water Map`}</title>
        <meta name="description" content="testing react helmet" />
        <meta name="keywords" content="react,seo,helmet" />
      </Helmet>
      <div className="lg:container mx-auto">
        <div className="grid xl:grid-cols-10 px-4 md:px-8 bg-white spaceTop">
          <div className="col-span-2 mr-2 relative hidden xl:block">
            <div className="absolute w-full">
              <div className="fixed">
                {Object.keys(selectedPlace).length > 0 && (
                  <div className="font-bold text-gray-600 text-xl mb-4 border-b-2 border-gray-200 p-2" style={{maxWidth: "280px"}}>
                    {selectedPlace.text}
                  </div>
                )}

                <Scrollspy
                  className="scrollNav"
                  offset={-115}
                  items={[
                    "section-1",
                    "section-2",
                    "section-3",
                    "section-4",
                    "section-5",
                    "section-6",
                    "section-7",
                  ]}
                  currentClassName="is-current"
                >
                  <li>
                    <a href="#section-1">
                      <img src={imgBeachDunesAndRenourrishment} /> Floodplain
                      Reconnection areas
                    </a>
                  </li>
                  <li>
                    <a href="#section-2">
                      <img src={imgPonds} /> Runoff Attenuation Areas
                    </a>
                  </li>
                  <li>
                    <a href="#section-3">
                      <img src={imgUpgradedRoads} /> Floodplain Woodland
                      Planning Areas
                    </a>
                  </li>
                  <li>
                    <a href="#section-4">
                      <img src={imgRainGardensAndBioswales} /> Woodland Water
                      Infiltration Areas
                    </a>
                  </li>
                  <li>
                    <a href="#section-5">
                      <img src={imgUpgradedRoads} /> Woodland Planting near
                      Watercourses Areas
                    </a>
                  </li>
                  <li>
                    <a href="#section-6">
                      <img src={imgSupport} /> Case Studies
                    </a>
                  </li>
                </Scrollspy>
              </div>
            </div>
          </div>
          <div className="col-span-8 resultSec xl:pl-5">
            <section id="section-1" className="min-h-screen my-6 py-6">
              <div className="pb-6 w-3/4 lg:w-2/4 mx-auto">
                <h2>
                  Floodplain Reconnection Potential
                </h2>
                <p className="py-6">
                  This map shows the possible locations where reconnection
                  between a watercourse and its natural floodplain may be
                  possible. The dataset is designed to help identifying areas
                  where there is poor connectivity in which flood waters could
                  be attenuate to reduce it moving downstream rapidly.
                </p>
                <p>
                  More details can be found in working with natural processes to
                  reduce flood risk
                </p>
                <div class="flex item-center mt-5">
                  <span class="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3"></span>{" "}
                  Flood Reconnection Potential Areas
                </div>
              </div>
              <div id="mapFloodplainReconnection" class="ol-map"></div>
              {/* <Map
                projection="EPSG:27700"
                center={
                  Object.keys(selectedPlace).length > 0
                    ? transform(selectedPlace.center, "EPSG:4326", "EPSG:27700")
                    : center
                }
                zoom={zoom}
              >
                <Layers>
                 
                  <WMSTileLayer
                    source={tilewms(
                      "https://environment.data.gov.uk/spatialdata/flood-map-for-planning-rivers-and-sea-flood-zone-3/wms",
                      "Flood_Map_for_Planning_Rivers_and_Sea_Flood_Zone_3"
                    )}
                    zIndex={3}
                    layerName="Flood Zone 3"
                  />
                </Layers>
                <Controls>
                  <FullScreenControl />
                  <ZoomControl />
                </Controls>
              </Map> */}
            </section>

            <section
              id="section-2"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="p-6 w-3/4 lg:w-2/4 mx-auto">
                <h2>
                  Runoff Attenuation Areas
                </h2>
                <p className="py-6">
                  These maps show the potential location where it may be
                  possible to temporarily store water and attenuate floods
                  during high flows. The areas of ponding or accumulation are
                  between 100 and 5000 metres squared, and have been tagged
                  where they fall on an area of slope steeper than 6% as gully
                  blocking opportunities.
                </p>
                <p>
                  All the potential areas have been constrained so that they are
                  not in urban areas or on roads, rails or canals. The data does
                  not does not provide information on design but it give
                  pointers on the potential areas for attenuation.
                </p>
              </div>
              <Tabs onSelect={(index) => selectedTab_attenuation(index)}>
                <TabList>
                  <Tab>
                    Attenuation Areas{" "}
                    <div className="text-xs">1:30 Storm Events</div>
                  </Tab>
                  <Tab>
                    Attenuation Areas{" "}
                    <div className="text-xs">1:100 Storm Events</div>
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="grid lg:grid-cols-5">
                    <div className="md:col-span-2 lg:border-r border-gray-300 px-4 py-8 xl:p-8 text-center text-xs flex">
                      <div class="flex item-center self-center">
                        <span class="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3"></span>{" "}
                        Potential Runoff Attenuation for 1 in 30 Storm Events
                      </div>
                    </div>
                    <div className="p-8 md:col-span-3">
                      <h3 className="text-xl font-black">
                        Attenuation Areas for 1 in 30yr Storm Events
                      </h3>
                      <p className="py-6">
                        The 1 in 30 (3.3% Annual Exceedance Probability) refer
                        to the flood zone 3b. These are areas at very high flood
                        risk.
                        <br />
                        <br />
                        The map is based on the Risk of Flooding from Surface
                        Water datasets and identifies areas of high flow
                        accumulations for surface water maps.
                      </p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="grid lg:grid-cols-5">
                    <div className="md:col-span-2 lg:border-r border-gray-300 px-4 py-8 xl:p-8 text-center text-xs flex">
                      <div class="flex item-center self-center">
                        <span class="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3"></span>{" "}
                        Potential Runoff Attenuation for 1 in 100 Storm Events
                      </div>
                    </div>
                    <div className="p-8 md:col-span-3">
                      <h3 className="text-xl font-black">
                        Attenuation Areas for 1 in 100yr Storm Events
                      </h3>
                      <p className="py-6">
                        The 1 in 100 (1% Annual Exceedance Probability) refer to
                        the flood zone 3a. These are areas at very high flood
                        risk.
                        <br />
                        <br />
                        The map is based on the Risk of Flooding from Surface
                        Water datasets and identifies areas of high flow
                        accumulations for surface water maps.
                      </p>
                    </div>
                  </div>
                </TabPanel>
                <div ref={mapElement_attenuationmap} class="ol-map"></div>
              </Tabs>
            </section>

            <section
              id="section-3"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="py-6 w-3/4 lg:w-2/4 mx-auto">
                <h2>
                  Floodplain Woodland Planting Areas
                </h2>
                <p className="py-6">
                  This map shows potential locations where tree planting may be
                  possible, and would be effective to attenuate flooding. The
                  areas are based on the fluvial Flood Zone 2 of the Flood Map
                  for Planning..
                </p>
                <p>
                  More details can be found in working with natural processes to
                  reduce flood risk
                </p>
                <div class="flex item-center mt-5">
                  <span class="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3"></span>{" "}
                  Floodplain Woodland Planting Potential
                </div>
              </div>
              <div id="mapFloodplaindWoodlandPlanting" class="ol-map"></div>
              {/* <Map
                projection="EPSG:27700"
                center={
                  Object.keys(selectedPlace).length > 0
                    ? transform(selectedPlace.center, "EPSG:4326", "EPSG:27700")
                    : center
                }
                zoom={zoom}
              >
                <Layers>
                  <WMSTileLayer
                    source={xyz(
                      "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
                      "Made by Rotten Grapes",
                      20
                    )}
                    zIndex={0}
                    layerName="Basemap"
                  />
                  <WMSTileLayer
                    source={tilewms(
                      "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-reservoirs-maximum-flood-extent/wms",
                      "Risk_of_Flooding_from_Reservoirs_Maximum_Flood_Extent"
                    )}
                    zIndex={1}
                    layerName="Extent"
                  />
                </Layers>
                <Controls>
                  <FullScreenControl />
                  <ZoomControl />
                </Controls>
              </Map> */}
            </section>

            <section
              id="section-4"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="py-6 w-3/4 lg:w-2/4 mx-auto">
                <h2>
                  Woodland Water Infiltration Areas
                </h2>
                <p className="py-6">
                  This map shows the potential for wider catchment woodland
                  plantation. These areas shows the locations where there are
                  slowly permeable soils, where scrub and tree planting may be
                  most effective to increase infiltration and hydrological
                  losses.
                  <br />
                  <br />
                  More details can be found in working with natural processes to
                  reduce flood risk
                </p>
                <div className="flex item-center">
                  <span class="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3"></span>{" "}
                  Wider Catchment Woodland
                </div>
              </div>
              <div id="mapWoodlandWaterInfiltration" class="ol-map"></div>
              
            </section>

            <section
              id="section-5"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="py-6 w-3/4 lg:w-2/4 mx-auto">
                <h2>
                  Woodland Planting near Watercourses Areas
                </h2>
                <p className="py-6">
                  This map shows potential areas where tree planting may be
                  possible on smaller floodplains close to flow pathways, and
                  effective to attenuate flooding. This map points to riparian
                  areas not already wooded.
                  <br />
                  <br />
                  More details can be found in working with natural processes to
                  reduce flood risk
                </p>
                <div className="flex item-center">
                  <span class="w-5 h-5 inline-block rounded-full bg-blue-700 mr-3"></span>{" "}
                  Wider Catchment Woodland
                </div>
              </div>
              <div
                id="mapWoodlandPlantingNearWatercourses"
                class="ol-map"
              ></div>
              {/* <Map
                projection="EPSG:27700"
                center={
                  Object.keys(selectedPlace).length > 0
                    ? transform(selectedPlace.center, "EPSG:4326", "EPSG:27700")
                    : center
                }
                zoom={zoom}
              >
                <Layers>
                  <WMSTileLayer
                    source={xyz(
                      "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJnZXJpdiIsImEiOiJjazludXBxcGowMmc1M2ZuNndweXh6bXl5In0.6NJz4WYp0542hCoBwWHx6g",
                      "Made by Rotten Grapes",
                      20
                    )}
                    zIndex={0}
                    layerName="Basemap"
                  />
                  <WMSTileLayer
                    source={tilewms(
                      "https://environment.data.gov.uk/spatialdata/historic-flood-map/wms",
                      "Historic_Flood_Map"
                    )}
                    zIndex={1}
                    layerName="Historic Flooding"
                  />
                </Layers>
                <Controls>
                  <FullScreenControl />
                  <ZoomControl />
                </Controls>
              </Map> */}
            </section>

            <section
              id="section-6"
              className="min-h-screen border-t-2 border-gray-200 my-6 py-6"
            >
              <div className="p-6 w-3/4 lg:w-2/4 mx-auto">
                <h2>Case Studies</h2>
                <p className="py-6">
                  We have collated all the case studies evaluated as part of the
                  working with natural processes to reduce flood risk report.
                  You can browse by type of study and by location.
                </p>
              </div>

              <div className="grid lg:grid-cols-5 border-t border-gray-300">
                <div className="md:col-span-2 lg:border-r border-gray-300 px-4 py-8 xl:p-8 text-center text-xs flex">
                  <div className="grid grid-cols-5 bg-gray-100 self-center">
                    <div
                      onClick={(event) => ActiveLabel("All")}
                      className={active == "All" ? "selectedLavel" : ""}
                    >
                      <div className="p-2">All</div>
                      <div className="p-2">
                        <span class="w-5 h-5 inline-block rounded-full bg-gradient-to-r from-blue-700 via-red-700 to-purple-600"></span>
                      </div>
                    </div>
                    {/* onClick={ActiveLabel("Rivers")} */}
                    <div
                      onClick={(event) => ActiveLabel("Rivers")}
                      className={active == "Rivers" ? "selectedLavel" : ""}
                    >
                      <div className="p-2">Rivers</div>
                      <div className="p-2">
                        <span class="w-5 h-5 inline-block rounded-full bg-blue-700"></span>
                      </div>
                    </div>
                    <div
                      onClick={(event) => ActiveLabel("Woodlands")}
                      className={active == "Woodlands" ? "selectedLavel" : ""}
                    >
                      <div className="p-2">Woodlands</div>
                      <div className="p-2">
                        <span class="w-5 h-5 inline-block rounded-full bg-green-700"></span>
                      </div>
                    </div>
                    <div
                      onClick={(event) => ActiveLabel("Runoff")}
                      className={active == "Runoff" ? "selectedLavel" : ""}
                    >
                      <div className="p-2">Runoff</div>
                      <div className="p-2">
                        <span class="w-5 h-5 inline-block rounded-full bg-red-600"></span>
                      </div>
                    </div>
                    <div
                      onClick={(event) => ActiveLabel("Coasts")}
                      className={active == "Coasts" ? "selectedLavel" : ""}
                    >
                      <div className="p-2">Coasts</div>
                      <div className="p-2">
                        <span class="w-5 h-5 inline-block rounded-full bg-purple-600"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-8 md:col-span-3">
                  <h3 className="text-xl font-black">Case Studies</h3>
                  <p className="py-6">
                    The case studies have been compiled as part of the WWNP to
                    reduce flood risk report. See the map and check out examples
                    in which this work has been undertaken
                    <br />
                    <br />
                    The map is based on the Risk of Flooding from Surface Water
                    datasets and identifies areas of high flow accumulations for
                    surface water maps.
                  </p>
                </div>
              </div>
              <div ref={mapElement_mapCaseStudies} class="ol-map">
                {/* {clickedPoint.title == "" && ( */}
                  <div className="ol-popup">
                  <h3>{clickedPoint.title}</h3>
                    
                    <p><a className="underline" target='_blank' href={clickedPoint.link}>{clickedPoint.text}</a> </p>
                  </div>
                {/* )}  */}
                
              </div>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// const root = document.getElementById("root");
// ReactDOM.render(<NBSResultPage />, root);

export default NBSResultPage;

import React from "react";
import { Switch, Route } from "react-router-dom";
import SearchInput from "./SearchInput";
import NbsSearchInput from "./NbsSearchInput";
import "./Searchbar.css";

//  import image from '../components/images/div_1_image.jpg';
const Searchbar = () => {
  return (
    <header className="header">
      <div className="title space-y-4 relative mx-auto p-8 md:w-3/4 xl:w-3/5">
        <Switch>
          <Route exact path="/">
            <h1 className="text-2xl md:text-5xl">Simple and reliable maps</h1>
            <p>We are Water Experts committed to your livelihoods</p>
          </Route>
          <Route exact path="/nature-based-solutions">
            <h1 className="text-2xl md:text-5xl">Explore opportunities to manage flooding using Nature Based Solutions</h1>
            <p>Take steps to protect your assets and your community</p>
            <NbsSearchInput />
            <p>
                The information shown taken directly from the Environment Agency working with natural processes maps. Our maps are intended for information only.
            </p>
          </Route>
          <Route exact path="/flood-risk-map">
            <h1 className="text-2xl sm:text-3xl xl:text-5xl">Explore the flood risk map and find your flood risk</h1>
            <p>Take steps to protect your assets and yourself</p>
            <SearchInput />
            <p className="para">
              Our maps provide flood risk information and flood protection
              intervention in an easy to use format. Check out our maps below.
            </p>
          </Route>
        </Switch>
      </div>
    </header>
  );
};
export default Searchbar;

import React, { useReducer } from "react";
import uwContext from "./uwContext";
import uwReducer from "./uwReducer";
import axios from "axios";


const UwState = (props) => {
  const initialState = {
    selectedPlace: {},
    layerinfo: [],
    center: [-2.36966957036279, 54.2379333607472],
    zoom: 12,
    frmPage: false,
    featureSet: [],
    city: {},
    featuredCities: [],
    map: null
  };

  const [state, dispatch] = useReducer(uwReducer, initialState);

  const setPlace = result => dispatch({ type: "set_place", payload: result });

  const getLayerInfo = () => {
    axios
      .get(`/layer.json`)
      .then((res) =>
        dispatch({ type: "set_layerinfo", payload: res.data.layers })
      );
  };

  const setMap = result => dispatch({ type: "set_map", payload: result });

  const setCenter = result => dispatch({ type: "set_center", payload: result });

  const setZoom = result => dispatch({ type: "set_zoom", payload: result });

  const setFrmPage = result => dispatch({ type: "set_frmPage", payload: result });

  const setFeatureSet = async (value) => {
    await axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&types=address,region,poi,country,district,locality,neighborhood,postcode&country=gb`
      )
      .then((res) => {
        res &&
          res.data.features &&
          dispatch({ type: "set_featureSet", payload: res.data.features });
      });
  };

  const resetFeatureSet = () => dispatch({ type: "reset_featureSet", payload: [] });

  const getCity = async (value) => {
    await axios
      .get(
        `https://urbanwater-cities-default-rtdb.firebaseio.com/Cities/${value}.json`
      )
      .then((res) => {
        res && res.data ? dispatch({ type: "get_city", payload: res.data }) : dispatch({ type: "get_city", payload: {} });
      });
  };

  const getFeaturedCities = async () => {
    await axios
      .get('https://urbanwater-cities-default-rtdb.firebaseio.com/Cities.json')
      .then((res) => {
        res && res.data && dispatch({type: "get_FeaturedCities", payload: res.data})
      })
  }

  return (
    <uwContext.Provider
      value={{
        selectedPlace: state.selectedPlace,
        layerinfo: state.layerinfo,
        center: state.center,
        zoom: state.zoom,
        frmPage: state.frmPage,
        featureSet: state.featureSet,
        city: state.city,
        featuredCities: state.featuredCities,
        map: state.map,
        setPlace,
        getLayerInfo,
        setCenter,
        setZoom,
        setFrmPage,
        setFeatureSet,
        resetFeatureSet,
        getCity,
        getFeaturedCities,
        setMap
      }}
    >
      {props.children}
    </uwContext.Provider>
  );
};

export default UwState;

import React, { useContext, useEffect } from "react";

import "./Navbar.css";
import {
  Link,
  NavLink,
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import SearchInput from "../Searchbar/SearchInput";
import NbsSearchInput from "../Searchbar/NbsSearchInput";
import logo from "../../images/Urban_Water_cv.png";
import uwContext from "../../context/uw/uwContext";

const Navbar = () => {
  const { setFrmPage } = useContext(uwContext);
  // const match = useRouteMatch("/flood-risk-map");
  const pathLocation = useLocation();
  useEffect(() => {
    if (pathLocation.pathname == "/flood-risk-map") {
      setFrmPage(true);
    } else {
      setFrmPage(false);
    }
  }, []);

  return (
    <div>
      <nav className="sticky border-b z-50 w-full nav flex flex-wrap items-center justify-between px-4">
        <div className="flex flex-no-shrink items-center mr-6 py-3 text-grey-darkest">
          <Link exact to="/">
            <img
              src={logo}
              className="fill-current h-14 mr-2 "
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="1.5"
              clipRule="evenodd"
              viewBox="0 0 716 895"
            />
          </Link>
        </div>

        <Switch>
          {/* <Route
              path="/"
              render={(props) =>
                props.location.pathname !== "/" &&  props.location.pathname !== "/flood-risk-map" && (
                  <div className="relative w-1/3 shadow-md rounded-md border border-color-gray-400">
                    <SearchInput />
                  </div>
                )
              }
            /> */}
          <Route path="/explore-towns">
            <div className="relative lg:block hidden w-1/4 shadow-md rounded-md border border-color-gray-400">
              <SearchInput />
            </div>
          </Route>
          <Route path="/nature-based-solutions-:place">
            <div className="relative lg:block hidden w-1/4 shadow-md rounded-md border border-color-gray-400">
              <NbsSearchInput />
            </div>
          </Route>
        </Switch>

        <input className="menu-btn hidden" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none"
          htmlFor="menu-btn"
        >
          <span className="navicon bg-grey-darkest flex items-center relative"></span>
        </label>

        <ul className="menu border-b md:border-none flex justify-end list-reset m-0 w-full md:w-auto md:text-sm">
          <li className="border-t md:border-none">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/"
              className="block md:inline-block p-3 no-underline text-grey-darkest hover:text-grey-darker "
            >
              Home
            </NavLink>
          </li>
          {/* onClick={ (event) => {
              setFrmPage(true)
              }} */}
          <li className="border-t md:border-none">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/flood-risk-map"
              className="block md:inline-block p-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              Flood Risk Map
            </NavLink>
          </li>

          <li className="border-t md:border-none">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/nature-based-solutions"
              className="block md:inline-block p-3 no-underline text-grey-darkest hover:text-grey-darker "
            >
              Nature Based Solutions
            </NavLink>
          </li>

          <li className="border-t md:border-none">
            <a
              href="https://urban-water.co.uk/about-us/"
              target="_blank"
              className="block md:inline-block p-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              About
            </a>
          </li>

          {/* <li className="border-t md:border-none">
              <Link exact
                to="/explore-towns"
                className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker "
              >
                Explore Towns
              </Link>
            </li> */}

          <li className="border-t md:border-none">
            <a
              href="https://urban-water.co.uk/contact/"
              className="block md:inline-block p-3 no-underline text-grey-darkest hover:text-grey-darker"
            >
              Contact
            </a>
          </li>
        </ul>
        <Switch>
          <Route path="/explore-towns">
            <div className="relative block lg:hidden w-full shadow-md rounded-md border border-color-gray-400 mb-2">
              <SearchInput />
            </div>
          </Route>
          <Route path="/nature-based-solutions-:place">
            <div className="relative block lg:hidden w-full shadow-md rounded-md border border-color-gray-400 mb-2">
              <NbsSearchInput />
            </div>
          </Route>
        </Switch>
      </nav>
    </div>
  );
};

export default Navbar;

import React, { Fragment } from "react";
import Searchbar from "../../components/Searchbar/Searchbar";
import OurServices_card from "../../components/OurServices_card/OurServices_card";
import ExploreUrban_card from "../../components/ExploreUrban_card/ExploreUrban_card";
import Faqs from "../../components/Faqs/Faqs";
import { Helmet } from "react-helmet";

const NatureBasedSolutions = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Urban Water Map</title>
        <meta name="description" content="testing react helmet" />
        <meta name="keywords" content="react,seo,helmet" />
      </Helmet>
      <Searchbar />
      <div className="p-8 my-16 space-y-6 md:w-3/4 mx-auto">
        <p>
          We have created this website for householders, developers, planers and
          architects who would like to explore and get involve in natural Based
          Flood Management initiatives that protect their developements.
        </p>
        <p>
          Natured Based Solutions are informed by the Environment Agency work on
          Natural Processes to reduce flood risk report.
        </p>
        <p>
          Traditional flood risk intervention at developement level have a
          limited impact in the control of flooding. You can get involve in
          projects that can deliver environmental and flood risk Benefits alike
          while protecting your developement from flood risk.
        </p>
        <p>
          Water Experts committed to protect your livelihoods. Join the success
          of our clients.
        </p>

        <hr />

        <h2 className="text-3xl font-black mb-4">What have we included in the maps?</h2>
        <p>We have included the maps results of the Environment Agency work on Natural Process to reduce flooding</p>
        <ul className="list-disc pl-8">
          <li>Flood plan Woodland Planting Potential</li>
          <li>Riparian Woodland Planting Potential</li>
          <li>Wider Catchment Woodland</li>
          <li>Floodplain Reconnection Potential</li>
          <li>Sunoff Attenuation Features 3.3% AEP</li>
          <li>Sunoff Attenuation Features 1% AEP</li>
          <li>Woodland Constraints</li>
        </ul>
        <p>
          We have also plotted case studies on the maps so you can get exaples of the work done for similar catchments.
        </p>
        <p>
          We aim to give you sufficient information to help you explore alternatives to protect your developement and assets.
        </p>

        <hr />

        <h2 className="text-3xl font-black mb-4">How can you learn more about Natural Based Solutions?</h2>
        <p>You can search for your nearest <a href="https://catchmentbasedapproach.org/get-involved/" target="_blank">local catchment partnership</a></p>
        <p>
          We can also help you to explore additional possibilities if you can't find the right partner for your potential project.
        </p>
      </div>
      <OurServices_card />
      <Faqs />
    </Fragment>
  );
};

export default NatureBasedSolutions;

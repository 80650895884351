import React from "react";
import { Bar } from "react-chartjs-2";

class Chart extends React.Component {
  constructor(props) {
    super(props);
    const data = [];
    const backgroundColor = [];
    for(let i = 0; i < Object.keys(props.chartData.dataset).length; i++) {
      let a = Object.keys(props.chartData.dataset)[i]
      data.push(props.chartData.dataset[a].data)
      backgroundColor.push(props.chartData.dataset[a].backgroundColor)
    }
    this.state = {
      chartData: {
        labels: Object.keys(props.chartData.dataset),
        datasets: [
            {
                label: props.chartData.label,
                data: data,
                backgroundColor: backgroundColor,
                borderWidth: 3
            }
        ],
      },
    };
  }

 
  render() {
    return (
        <div className="chart">
            <Bar
                data={this.state.chartData}
                options={{}}
            />
        </div>
    );
  }
}
export default Chart;

export default (state, action) => {
  switch (action.type) {
    case "set_place":
      return {
        ...state,
        placeName: action.payload.place,
        searchValue: action.payload.searchValue,
        selectedPlace: action.payload.selectedFeature[0]
      };
    case "set_layerinfo":
      return {
        ...state,
        layerinfo: action.payload,
      };
    case "set_map":
      return {
        ...state,
        map: action.payload,
      };
    case "set_center":
      return {
        ...state,
        center: action.payload,
      };
    case "set_zoom":
      return {
        ...state,
        zoom: action.payload,
      };
    case "set_frmPage":
      return {
        ...state,
        frmPage: action.payload,
        // selectedPlace: "Type Enter ZIP Code, City, or State"
      };
    case "set_featureSet":
      return {
        ...state,
        featureSet: action.payload
      };
    case "reset_featureSet":
      return {
        ...state,
        featureSet: action.payload,
        // selectedPlace: {}
      };
    case "get_city":
      return {
        ...state,
        city: action.payload
      };
    case "get_FeaturedCities":
      let cities = []
      for(let i=0; i<Object.keys(action.payload).length; i++) {
        cities.push(action.payload[Object.keys(action.payload)[i]])
      }
      return{
        ...state,
        featuredCities: cities
      };
    default:
      return state;
  }
};

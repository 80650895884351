import React, { Fragment } from "react";
import Searchbar from "../../components/Searchbar/Searchbar";
import OurServices_card from "../../components/OurServices_card/OurServices_card";
import ExploreUrban_card from "../../components/ExploreUrban_card/ExploreUrban_card";
import Faqs from "../../components/Faqs/Faqs";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Urban Water Map</title>
        <meta name="description" content="testing react helmet" />
        <meta name="keywords" content="react,seo,helmet" />
      </Helmet>
      <Searchbar />
      <div className="p-8 space-y-3 md:w-3/4 mx-auto">
        <p>
          We believe in simplicity and reliability. We have created this page to
          give you the flood risk information in a reliable format.
        </p>
        <p>
          Water Experts committed to protect your livelihoods. Joint the success
          of our clients!
        </p>
      </div>
      <OurServices_card />
      <ExploreUrban_card />
      <div className="bg-gray-100">
        <Faqs />
      </div>
    </Fragment>
  );
};

export default HomePage;

import React, { Fragment } from "react";
import ExploreUrban_card from "../../components/ExploreUrban_card/ExploreUrban_card";
import { Helmet } from "react-helmet";

const ExploreTowns = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Explore Towns | Urban Water Map</title>
        <meta name="description" content="testing react helmet" />
        <meta name="keywords" content="react,seo,helmet" />
      </Helmet>
      <div className="lg:container my-8">
        <ExploreUrban_card />
      </div>
    </Fragment>
  );
};

export default ExploreTowns;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import UwState from "./context/uw/UwState";
// import logo from './logo.svg';
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ResultPage from "./Pages/ResultPage/ResultPage";
import HomePage from "./Pages/HomePage/HomePage";
import ExploreTowns from "./Pages/ExploreTowns/ExploreTowns";
import FloodRiskMap from "./Pages/FloodRiskMap/FloodRiskMap"
import NatureBasedSolutions from "./Pages/NatureBasedSolutions/NatureBasedSolutions"
import NbsResultPage from "./Pages/NbsResultPage/NbsResultPage";
import TestMap from "./Pages/TestMap/TestMap"

const App = () => {
  return (
    <UwState>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route exact path={"/explore-towns"} component={ExploreTowns} />
          <Route
            path="/explore-towns/flood-risk-map-:place"
            component={ResultPage}
          />
          <Route exact path={"/flood-risk-map"} component={FloodRiskMap} />
          <Route exact path={"/nature-based-solutions"} component={NatureBasedSolutions} />
          <Route
            path="/nature-based-solutions-:place"
            component={NbsResultPage}
          />
          <Route exact path={"/test-map"} component={TestMap} />
        </Switch>
        <Footer />
      </Router>
    </UwState>
  );
};

export default App;

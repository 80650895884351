import React, { Component } from "react";
import "./OurServices_card.css";
export class OurServices_card extends Component {
  
  
  render() {
    const 
      Services = [{
        "id " : 1,
        "image" : "https://firebasestorage.googleapis.com/v0/b/urbanwater-cities.appspot.com/o/flood-risk-assessment.jpg?alt=media&token=256eca44-ec58-43f6-9b81-fdae7a9e195e",
        "text" : "Compliant  flood risk assessment with the Planning Policy and the Environment Agency regulations",
        "title" : "Flood Risk Assessment"
      }, {
        "id" : 2,
        "image" : "https://firebasestorage.googleapis.com/v0/b/urbanwater-cities.appspot.com/o/Drainage-strategy-urban-water.jpg?alt=media&token=cb189853-f7a3-4d26-9a18-1a661e5aa3a9",
        "text" : "Sustainable Drainage strategies and foul water drainage designs compliant with the local and national drainage guidance\",",
        "title" : "Drainage Design"
      }, {
        "id" : 3,
        "image" : "https://firebasestorage.googleapis.com/v0/b/urbanwater-cities.appspot.com/o/Climate-change-adaptation-urban-water.jpg?alt=media&token=7d9cb2c9-1aba-44e7-b4c6-3e4eef927583",
        "text" : "Design of green infracture and natured based solutions to zero Carbon for the protection of communities and developements\",",
        "title" : "Climate Change Adaptation"
      } ],
     

  renderCard = (cardService, index) => {
      return (
        
          <div key={index} className="wrapper bg-white rounded-b-md shadow-lg overflow-hidden">
            <div>
              <img className="div_2_img" src={cardService.image} alt="montaña" />
            </div>
            <div className="p-3 space-y-8">
              <h3 className=" h3_card text-gray-700 font-semibold text-md">
                {cardService.title}
              </h3>
              <p className="text-sm text-gray-400 leading-sm">{cardService.text}</p>
            </div>
            <button className="w-51 flexDirection-center justify-center py-2 text-white font-semibold transition duration-300 hover:bg-blue-400">
              Learn More
            </button>
          
          </div>
        
      );
    };
    return (
      <div className="mb-8 py-8 bg-gray-100">
        <h2 className="text-2xl font-black mx-8">

          Our Services
        </h2>
        <p className="mx-8">
          We can help your project from planning approval to construction
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 m-12">{Services.map(renderCard)}</div>
       
      </div>
    );
  }
}

export default OurServices_card;

import React, { Component, useContext, useEffect } from "react";
import "./ExploreUrban_card.css";
import uwContext from "../../context/uw/uwContext";
import {
  Link,
} from "react-router-dom";

const ExploreUrban_card = () => {
  const { featuredCities, getFeaturedCities } = useContext(uwContext);

  // const Cities = [
  //   {
  //     id: 1,
  //     image:
  //       "https://firebasestorage.googleapis.com/v0/b/urbanwater-cities.appspot.com/o/flood%20map%20oxford.jpg?alt=media&token=ada04f7a-e20b-4585-8925-2a7ed4d79f4e",
  //     text:
  //       "14% properties at risk of flooding 57,000 properties at high or medium risk of flooding",
  //     title: "London",
  //   },
  //   {
  //     id: 2,
  //     image:
  //       "https://firebasestorage.googleapis.com/v0/b/testproject-urban.appspot.com/o/images%2Fflood%20map%20york.jpg?alt=media&token=a83e1a6f-1d78-4dc2-ae21-e04f2ace1367",
  //     text:
  //       "7% of properties at risk of flooding 3,334 properties at high or medium risk of flooding",
  //     title: "York",
  //   },
  //   {
  //     id: 3,
  //     image:
  //       "https://firebasestorage.googleapis.com/v0/b/testproject-urban.appspot.com/o/images%2Fflood%20map%20dorset.jpg?alt=media&token=b339787a-c260-45eb-b627-e2b3620dde12",
  //     text:
  //       "5% of properties at risk of flooding 6,651 properties at high or medium risk of flooding",
  //     title: "Dorset",
  //   },
  //   {
  //     id: 4,
  //     image:
  //       "https://firebasestorage.googleapis.com/v0/b/testproject-urban.appspot.com/o/images%2Fflood%20map%20kent.jpg?alt=media&token=bff0d4fc-afc3-4c4e-a3a6-ee35dab2e91e",
  //     text:
  //       "9% properties at risk of flooding 27,835 properties at high or medium risk of flooding",
  //     title: "Kent",
  //   },
  //   {
  //     id: 5,
  //     image:
  //       "https://firebasestorage.googleapis.com/v0/b/testproject-urban.appspot.com/o/images%2Fflood%20risk%20map%20bristol.jpg?alt=media&token=46589376-5e6c-4569-8efc-8fc47bfa356f",
  //     text:
  //       "7% properties at risk of flooding 3,540 properties at high or medium risk of flooding",
  //     title: "Bristol",
  //   },
  //   {
  //     id: 6,
  //     image:
  //       "https://firebasestorage.googleapis.com/v0/b/urbanwater-cities.appspot.com/o/flood%20map%20oxford.jpg?alt=media&token=ada04f7a-e20b-4585-8925-2a7ed4d79f4e",
  //     text:
  //       "6% properties at risk of flooding 3,521 properties at high or medium risk of flooding",
  //     title: "Oxford",
  //   },
  // ];
  useEffect(() => {
    getFeaturedCities();
  }, []);
  
  const renderCard = (cardCities, index) => {
    return (
        <a href={cardCities.pageUrl} key={index} className= "wrapper bg-gray-50 rounded-md shadow-lg  overflow-hidden">
          <div className="card_img" style={{backgroundImage: `url(${cardCities.image})`}}>
            {/* <img  src={cardCities.image} alt="montaña" /> */}
          </div>
          <div className="p-3 space-y-3">
            <h3 className=" h3_card text-gray-700 font-semibold text-md capitalize ">
              {cardCities.title}
            </h3>
            <p className="text-sm text-gray-400 leading-sm">
              {cardCities.propertiesPercentage}% properties at risk of flooding<br />
              {cardCities.properties} properties at high or medium risk of flooding
              </p>
          </div>
        </a>
    );
  };
  return (
    <div>
      <h2 className="text-2xl font-black mx-8">Explore Urban flood risk</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 m-12">{featuredCities.map(renderCard)}</div>
      <div>
        <br />
      </div>
    </div>
  );
};

export default ExploreUrban_card;
